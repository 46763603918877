import React, { useState } from 'react';
import axios from 'axios';
import webSocket from 'socket.io-client'
import { getDeviceData, getDeviceHistoryData } from "src/function/axios";
import hexToRgba from 'hex-to-rgba';

import {
  Container,
  Grid,
  makeStyles,
  Button,
  Card,
  CardContent,
  CardHeader,
  Box,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Budget from './Budget';
import LatestOrders from './LatestOrders';
import LatestProducts from './LatestProducts';
import Network from './Network';
import Cpu from './Cpu';
import Ram from './Ram';
import Process from './Process';
import SshPopup from './SshPopup';


import TasksProgress from './TasksProgress';
import TotalCustomers from './TotalCustomers';
import TotalProfit from './TotalProfit';
import TrafficByDevice from './TrafficByDevice';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { useAuthContext } from 'src/provider/AuthProvider';

import InfoCard from './InfoCard';
import { min } from 'lodash';

const queryString = require('query-string');
var moment = require('moment');
var _ = require('lodash');


const displayOption = [
  'latest', 'today', 'last 7 days',
  //  'weekly'
]

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DeviceReport = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const { authData } = useAuthContext();
  const [sshOpen, setSshOpen] = useState(false)

  const parsed = queryString.parse(location.search);
  let deviceId = parsed.id;

  const [oridata, setOridata] = useState(null);
  const [data, setData] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [lineColor, setLineColor] = useState("rgba(220,20,60,1)");


  const [deviceInfo, setDeviceInfo] = useState(null);


  const [display, setDisplay] = useState("latest");
  const [displayData, setDisplayData] = useState(null);

  //ws
  const [ws, setWs] = useState(null)


  async function fetchData() {
    let token = authData.token;

    getDeviceData({ deviceId: deviceId }, token)
      .then(res => {
        const result = res.data;
        console.log(result);
        if (result.result === "success") {
          if (result.data.color) {
            setLineColor(hexToRgba(result.data.color))
          }

          let newOriData2 = Object.assign({}, result.data);
          console.log('newOriData2: ', newOriData2);

          setData(newOriData2)
          setDisplayData(result.data);
          let newOriData = Object.assign({}, result.data);
          setOridata(newOriData);

          setDeviceInfo(result.data.deviceInfo);
        }
      })
  }

  async function fetchDataHistory() {
    let token = authData.token;

    getDeviceHistoryData({ deviceId: deviceId }, token)
      .then(res => {
        const result = res.data;
        console.log(result);
        if (result.result === "success") {
          setHistoryData(result.data)
        }
      })
  }

  React.useEffect(() => {
    console.log("DeviceListView useEffect:");

    //web socket
    // connectWebSocket();
    console.log('deviceId: ', deviceId);

    fetchData();
    fetchDataHistory();
  }, []);


  React.useEffect(() => {
    console.log('oridata: ', oridata);
  }, [oridata]);




  React.useEffect(() => {
    console.log("DeviceListView useEffect:");
    if (ws) {
      //連線成功在 console 中打印訊息
      console.log('success connect!')
      //設定監聽
      initWebSocket()

      //join DeviceId Room
      ws.emit('join', deviceId);
      console.log('join room: ' + deviceId)

    }
  }, [ws]);

  const initWebSocket = () => {
    //對 getMessage 設定監聽，如果 server 有透過 getMessage 傳送訊息，將會在此被捕捉
    ws.on('getMessage', message => {
      console.log(message);
      fetchData();
    })
  }

  const handleDisplayOnClick = (display) => {
    if (!historyData) {
      return
    }
    // data : labels cpu ram ...
    switch (display) {
      case "latest":
        console.log('oridata: ', oridata);

        setDisplayData(oridata);
        break;

      case "today":
        console.log('today',);
        let today = moment().format("YYYY-MM-DD");
        console.log('today: ', today);

        let currentHour = moment().subtract(1, "minutes").format("HH");
        console.log('currentHour: ', currentHour);

        let hourLabel = [];

        for (let i = 0; i <= parseInt(currentHour); i++) {
          i < 10 ? hourLabel.push("0" + i.toString()) : hourLabel.push(i.toString());
        }
        console.log('hourLabel: ', hourLabel);
        
        console.log('historyData: ', historyData);
        let newData = historyData.filter(item => item.day == today)[0];
        
        console.log('newData: ', newData);

        let newHourData = [];

        if (!newData) {
          setDisplayData({ label: hourLabel });
          return
        }

        console.log('newData: ', newData);
        for (const item of Object.keys(newData.value)) {
          newData.value[item]["hour"] = item;
          newHourData.push(newData.value[item]);
        }

        newHourData = _.sortBy(newHourData, ['hour'])
        console.log('newHourData: ', newHourData);

        // console.log('oridata: ', oridata);

        let currentHourCount = 0;

        for (const hour of newHourData) {
          let totalCount = 0;
          let totalcpu = 0;
          let totalram = 0;
          let totalping = 0;

          for (const minData of Object.keys(hour)) {

            if (hour[minData].timestamp) {
              totalCount++;
              totalcpu += hour[minData].cpu;
              totalram += hour[minData].ram;
              totalping += hour[minData].ping;

            }
          }
          hour["cpu"] = totalcpu / totalCount;
          hour["ram"] = totalram / totalCount;
          hour["ping"] = totalping / totalCount;

        }

        let mappedHourData = []

        for (var item of hourLabel) {
          console.log('item: ', item);
          let search = _.find(newHourData, ['hour', item]);
          console.log('search: ', search);
          if (search) {
            mappedHourData.push(search)
          } else {
            mappedHourData.push({
              hour: item,
              cpu: 0,
              ram: 0,
              ping: 0,
            })
          }
        }
        console.log('mappedHourData: ', mappedHourData);

        let newDisplayData = {
          cpuData: mappedHourData.map(item => item.cpu),
          pingData: mappedHourData.map(item => item.ping),
          ramData: mappedHourData.map(item => item.ram),
          label: mappedHourData.map(item => item.hour)
        }

        console.log('newDisplayData: ', newDisplayData);
        console.log('oridata: ', oridata);

        setDisplayData(newDisplayData);
        break;

      case "last 7 days":
        let dayLabel = [];
        //create a day array
        for (let i = 7; i >= 0; i--) {
          dayLabel.push({ dayLabel: moment().subtract(i, "d").format("YYYY-MM-DD") })
        }
        console.log('dayLabel: ', dayLabel);

        //sum up all data in historyData

        for (const item of historyData) {
          console.log('item: ', item);
          let dayTotalCount = 0;
          let dayTotalRam = 0;
          let dayTotalCpu = 0;
          let dayTotalPing = 0;

          //sum up all day data
          for (const hour of Object.keys(item.value)) {
            // console.log('hour: ', hour);
            for (const min of Object.keys(item.value[hour])) {
              if (typeof item.value[hour][min] == 'object') {
                let minData = item.value[hour][min];
                // console.log('min: ', item.value[hour][min]);
                dayTotalCount++;
                dayTotalRam += minData["ram"];
                dayTotalCpu += minData["cpu"];
                dayTotalPing += minData["ping"];
              }
            }
          }
          //cal avg
          item["ramavg"] = (dayTotalRam / dayTotalCount).toFixed(2);
          item["cpuavg"] = (dayTotalCpu / dayTotalCount).toFixed(2);
          item["pingavg"] = (dayTotalPing / dayTotalCount).toFixed(2);
          console.log('item: ', item);
        }

        let datDataAry = [];
        for (var day of dayLabel) {
          // console.log('day: ', day);
          let newDayData = _.find(historyData, ['day', day.dayLabel]);
          // console.log('newDayData: ', newDayData);
          if (newDayData) {
            datDataAry.push(newDayData);
          } else {
            datDataAry.push(day);
          }
        }
        console.log('datDataAry: ', datDataAry);

        let newDailyDisplayData = {
          cpuData: datDataAry.map(item => item.cpuavg),
          pingData: datDataAry.map(item => item.pingavg),
          ramData: datDataAry.map(item => item.ramavg),
          label: dayLabel.map(item => item.dayLabel),
        }
        //
        setDisplayData(newDailyDisplayData);
        console.log('newDailyDisplayData: ', newDailyDisplayData);

        break;
      case "weekly":
        console.log('data: ', oridata);
        setDisplayData(null);

        break;
      default:
        break;
    }
  }


  return (
    <Page
      className={classes.root}
      title="DeviceReport"
    >
      <SshPopup open={sshOpen} setOpen={setSshOpen} deviceId={deviceId} data={data} />



      <Container maxWidth={false}>
        <Button variant="outlined" color="primary" onClick={() => { setSshOpen(true) }}>
          Open Live control panel
        </Button>

        <Grid
          container
          spacing={1}
        >


          <Grid item lg={3} sm={6} xl={3} xs={12} >
            <InfoCard title={"Device Name"} value={data ? data.deviceInfo.deviceName : ""} />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} >
            <InfoCard title={"Host Name"} value={data ? data.deviceInfo.os.hostname : ""} />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} >
            <InfoCard title={"CPU"} value={data ? data.deviceInfo.cpu.manufacturer + data.deviceInfo.cpu.brand : ""} />
          </Grid>


          <Grid item lg={3} sm={6} xl={3} xs={12} >
            <InfoCard title={"OS"} value={data ? data.deviceInfo.os.distro + " " + data.deviceInfo.os.release : ""} />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} >
            <InfoCard title={"Manufacturer"} value={data ? data.deviceInfo.system.manufacturer : ""} />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} >
            <InfoCard title={"Model"} value={data ? data.deviceInfo.system.model : ""} />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} >
            <InfoCard title={"System up time"} value={data ? data.uptime ? data.uptime.toFixed(2) + "hr" : "--" : ""} />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} >
            <InfoCard title={"Memory"} value={data ? (data.deviceInfo.mem.total / 1024 / 1024 / 1024).toFixed(1) + "Gb" : ""} />
          </Grid>

          <Grid item xs={12} >
            <Card >
              <CardHeader
                subheader=" "
                title="Display period"
              />
              <CardContent>


                <Box display="flex" flexDirection='row'>
                  {displayOption.map(option =>
                    <Box key={option} mr={1}>
                      <Button variant="contained" color="primary"
                        onClick={() => {
                          setDisplay(option);
                          handleDisplayOnClick(option);
                        }}
                        disabled={display == option}
                      >
                        {option}
                      </Button>
                    </Box>
                  )}
                </Box>

              </CardContent>
            </Card>

          </Grid>

          <Grid
            item
            // lg={6}
            // md={12}
            // xl={6}
            xs={12}
          >
            <Network deviceId={deviceId} deviceData={displayData} lineColor={lineColor} />
          </Grid>
          <Grid
            item
            // lg={6}
            // md={12}
            // xl={6}
            xs={12}
          >
            <Cpu deviceId={deviceId} deviceData={displayData} lineColor={lineColor} />
          </Grid>
          <Grid
            item
            // lg={6}
            // md={12}
            // xl={6}
            xs={12}
          >
            <Ram deviceId={deviceId} deviceData={displayData} deviceInfo={deviceInfo} lineColor={lineColor} />
          </Grid>

          <Grid
            item
            lg={6}
            md={12}
            xl={6}
            xs={12}
          >
            <Process data={data ? data.ramProcess : null} />
          </Grid>

        </Grid>
      </Container>
    </Page>
  );
};

export default DeviceReport;
