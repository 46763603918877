import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { getUserToken } from 'src/function/axios';
import { useAuthContext } from 'src/provider/AuthProvider';
import { useUIContext } from "src/provider/UIProvider";

// const user = {
//   avatar: '/static/images/avatars/avatar_6.png',
//   city: 'Los Angeles',
//   country: 'USA',
//   jobTitle: 'Senior Developer',
//   name: 'Katarina Smith',
//   timezone: 'GTM-7'
// };

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const Profile = ({ className, user, ...rest }) => {
  const classes = useStyles();
  const { authData } = useAuthContext();
  const { openSnackbars, setOpenSnackbars } = useUIContext();
  let token = authData.token;

  const handleGetUserToken = () => {
    console.log("getUserToken");
    
    getUserToken({ token: token})
      .then(res => {
        const result = res.data;
        console.log(result);
        if (result.result === "success") {
          navigator.clipboard.writeText(result.data)
            .then(() => {
              setOpenSnackbars({
                open: true,
                severity: "success",
                message: "User token copied!"
              });
              // Success!
            })
            .catch(err => {
              console.log('Something went wrong', err);
            });
        }
      })
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={user.avatar}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {user.username}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {user.role}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >

          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="primary"
          fullWidth
          variant="text"
          onClick={handleGetUserToken}
        >
          Get User Token
        </Button>
      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
