import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useUIContext } from "src/provider/UIProvider";
import { localBaseUrl } from "src/function/axios";

//date picker 
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Switch,
  InputAdornment,
  IconButton,
  Avatar,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,


} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import WifiIcon from '@material-ui/icons/Wifi';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

let _ = require("lodash");

const ThresholdOption = [
  {
    value: '30',
    label: '30 minutes'
  },
  {
    value: '60',
    label: '1 hour'
  },
  {
    value: '120',
    label: '2 hour'
  },
  {
    value: '180',
    label: '3 hour'
  },
  {
    value: '240',
    label: '4 hour'
  },
];

const repeatOption = [
  {
    value: 'weekday',
    label: 'weekday'
  },
  {
    value: 'weekend',
    label: 'weekend'
  },
  {
    value: 'always',
    label: 'always'
  },
];


const statusOption = [
  {
    value: 'active',
    label: 'active'
  },
  {
    value: 'pending',
    label: 'pending'
  },
  {
    value: 'expiring',
    label: 'expiring'
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  colorPicker: {
    fontSize: 40
  },
  colorPickerBorder: {
    border: "1px solid red",
    borderRadius: "50%",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const DeviceConfig = ({ className, data, setData, updateData, newImage, setnewImage, smartPlugs, ...rest }) => {
  const classes = useStyles();
  const [serialNumberLock, setserialNumberLock] = useState(true);

  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
  const [checked, setChecked] = React.useState(['wifi']);

  const [command, setcommand] = useState('')

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleDateChange = (date, name) => {
    console.log('date: ', date);
    console.log('data: ', data.alertInfo);
    setData(oriData => {
      return _.set(_.clone(oriData), name, date)
    });
    // setSelectedDate(date);
  };

  const {
    colorOption
  } = useUIContext();

  const handleChange = (event) => {
    let { name, value } = event.target;
    setData(oriData => {
      return _.set(_.clone(oriData), name, value)
    });
  };
  const handleSwitchChange = (event) => {
    let { name, value } = event.target;
    setData(oriData => {
      return _.set(_.clone(oriData), name, !_.get(oriData, name))
    });
  };

  const handleColorChange = (value) => {
    console.log('value: ', value);
    let name = "color";
    setData(oriData => {
      return _.set(_.clone(oriData), name, value)
    });
  };

  const handleAddCommand = () => {
    let value = command;

    if (_.trim(command) == '') {
      return
    }

    console.log(data['command']);
    console.log('data: ', data);
    console.log('value: ', value)
    let name = "command";
    if (!data['command']) {
      setData(oriData => {
        return _.set(_.clone(oriData), name, [value])
      });
    } else {
      setData(oriData => {
        return _.set(_.clone(oriData), name, [...data['command'], value])
      });
    }
    setcommand('');
  };

  const handleDeleteCommand = (value) => {
    let name = "command";
    console.log(_.indexOf(data['command'], value));
    let newArray = data['command'].splice(_.indexOf(data['command'], value), 1);
    console.log('newArray: ', newArray);
    setData(oriData => {
      return _.set(_.clone(oriData), name, oriData['command'])
    });
  };




  const fileOnChange = (event) => {
    console.log('event: ', event.target.files[0]);
    setnewImage(event.target.files[0])
  };


  const getSrc = () => {
    // URL.createObjectURL(event.target.files[0])
    if (newImage) {
      return URL.createObjectURL(newImage);
    } else if (data) {
      if (data.deviceImage) {
        console.log('data.deviceImage: ', data.deviceImage);
        return localBaseUrl + data.deviceImage;
      }

      // return localBaseUrl + "/images/5f72e58f71c68070798d4b51.jpeg"
    } else {
      return ""
    }
  }

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader=" "
          title="Device Config"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3} alignContent="center">
            <Grid item md={1} xs={2} >
              {

              }
              <Avatar
                alt={"a"}
                // src={localBaseUrl + "/images/5f72e58f71c68070798d4b51.jpeg"}
                src={getSrc()}
                className={classes.large}
              >
                {data ? data.deviceName.charAt(0) : ""}
              </Avatar>

            </Grid>
            <Grid item md={2} xs={5} style={{ alignSelf: "center" }} >

              <Button
                fullWidth
                variant="contained"
                component="label"
                color="primary"
              >
                Upload File
              <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={fileOnChange}
                />
              </Button>
            </Grid>

            <Grid item md={9} xs={12} >
              <TextField
                fullWidth
                label="Device Name"
                name="deviceName"
                onChange={handleChange}
                required
                value={data && data.deviceName || ""}
                variant="outlined"
              />
            </Grid>

            <Grid item md={9} xs={12} >
              <TextField
                fullWidth
                label="Description"
                name="description"
                onChange={handleChange}
                value={data && data.description || ""}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12} >
              <TextField
                fullWidth
                label="Serial Number"
                name="serialNo"
                onChange={handleChange}
                disabled={serialNumberLock}
                value={data && data.serialNo || ""}
                variant="outlined"
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setserialNumberLock(!serialNumberLock)}>
                        {serialNumberLock ? <EditIcon /> : <LockIcon />}
                      </IconButton>
                    </InputAdornment>
                }}

              />
            </Grid>
            {/* <Grid item md={6} xs={12} >
              <TextField
                fullWidth
                label="Status"
                name="status"
                onChange={handleChange}
                required
                value={data && data.status || ""}
                variant="outlined"
              />
            </Grid> */}
            <Grid item md={6} xs={12} >
              <TextField
                fullWidth
                label="Status"
                name="status"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={data && data.status || ""}
                variant="outlined"
              >
                {statusOption.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}

              </TextField>
            </Grid>

            <Grid item md={6} xs={12} >
              <TextField
                fullWidth
                label="Color"
                name="color"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={data && data.color || ""}
                variant="outlined"
              >
                <option
                  value={""}
                >
                  {""}
                </option>
                {colorOption.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item md={6} xs={12} >
              <Box display="flex" flexDirection="row">
                {colorOption.map((option) => (
                  <Box
                    key={option.value}
                    p={1}
                    mr={3}
                    onClick={() => handleColorChange(option.value)}
                  >
                    <FiberManualRecordIcon className={classes.colorPicker}
                      style={data && data.color == option.value ?
                        { color: option.value, border: "1px solid black", borderRadius: "50%", } :
                        { color: option.value }}
                    // style={{ color: option.value }}

                    />
                  </Box>
                ))}
              </Box>
            </Grid>

          </Grid>
        </CardContent>

        {/* ---------------------------------------------------------Alert--------------------------------------------------------- */}
        <CardHeader subheader=" " title="Alert Setting" />
        <Divider />

        <CardContent>
          <Grid container spacing={3} >
            <Grid item xs={12} >
              <Box display="flex" flexDirection="row" alignContent="center">
                <Box alignSelf="center">
                  Turn On
                </Box>
                <Box alignSelf="center">
                  <Switch
                    checked={data && data.isAlert || false}
                    onChange={handleSwitchChange}
                    name="isAlert"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} >
              <TextField
                fullWidth
                label="Alert Email Address"
                name="alertInfo.email"
                onChange={handleChange}
                required
                value={data && data.alertInfo.email || ""}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12} >
              <TextField
                fullWidth
                label="Alert Threshold"
                name="alertInfo.threshold"
                helperText="Receive email alert if device offline / no connection after exceeded threshold"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={data && data.alertInfo.threshold || ""}
                variant="outlined"
              >
                {ThresholdOption.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item md={6} xs={12} >
              <TextField
                fullWidth
                label="Repeat"
                name="alertInfo.repeat"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={data && data.alertInfo.repeat || ""}
                variant="outlined"
              >
                {repeatOption.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>


            <Grid item md={6} xs={12} >
              <ListSubheader>Alert Time Settings</ListSubheader>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">

                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Start At"
                    value={data && data.alertInfo.startAt || new Date('2020-01-01T08:00:00')}
                    onChange={(d) => handleDateChange(d, "alertInfo.startAt")}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="End At"
                    value={data && data.alertInfo.endAt || new Date('2020-01-01T21:00:00')}
                    onChange={(d) => handleDateChange(d, "alertInfo.endAt")}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>

            {/* command list */}
            <Grid item md={6} xs={12} >
              <List subheader={
                <>
                  <ListSubheader>
                    <Box display="flex">
                      Command Setting
                      <Box flexGrow='1'> </Box>
                      <Button>

                      </Button>
                    </Box>
                  </ListSubheader>
                </>
              } className={classes.root}>

                <ListItem>
                  <Box display="flex" alignItems='center' width='100%'>

                    <ListItemIcon>
                      <WifiIcon />
                    </ListItemIcon>

                    <Box flexGrow='1'>

                      <TextField
                        fullWidth
                        label="Command"
                        name="command"
                        margin='dense'
                        required
                        onChange={(event) => { console.log(event.target.value); setcommand(event.target.value) }}
                        value={command}
                        variant="outlined"
                        onKeyPress={(ev) => {
                          if (ev.key === 'Enter') {
                            // Do code here
                            handleAddCommand();
                            ev.preventDefault();
                          }
                        }}
                      />

                    </Box>

                    {/* <ListItemText id="switch-list-label-wifi" primary="Wi-Fi" /> */}
                    <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleAddCommand}>
                      <AddCircleIcon />
                    </IconButton>
                  </Box>

                </ListItem>

                {data && data.command ?
                  data.command.map((command, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <FiberManualRecordIcon />
                      </ListItemIcon>
                      <ListItemText id="switch-list-label-wifi" primary={command} />
                      <ListItemSecondaryAction>
                        <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => handleDeleteCommand(command)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                  :
                  null
                }
              </List>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />

        {/* ---------------------------------------------------------schedule--------------------------------------------------------- */}
        <CardHeader subheader=" " title="smart-plug setting" />
        <CardContent>
          <Grid container spacing={3} >
            <Grid item md={12} xs={12} >
              <TextField
                fullWidth
                label="Smart Plug Name"
                name="smartPlugName"
                onChange={handleChange}
                required
                value={data && data.deviceName || ""}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12} >
              <TextField
                fullWidth
                label="Smart Plug Name"
                name="smartPlug"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={data && data.smartPlug || ""}
                variant="outlined"
              >
                <option
                  value={""}
                >
                  {""}
                </option>
                {smartPlugs ? smartPlugs.map((option) => (
                  <option
                    key={option._id}
                    value={option._id}
                  >
                    {option.name}
                  </option>
                )) :
                  null
                }
              </TextField>
            </Grid>


            <Grid item md={6} xs={12} >
              <ListSubheader>Schedule Setting</ListSubheader>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Start At"
                    value={data && data.alertInfo.startAt || new Date('2020-01-01T08:00:00')}
                    onChange={(d) => handleDateChange(d, "alertInfo.startAt")}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="End At"
                    value={data && data.alertInfo.endAt || new Date('2020-01-01T21:00:00')}
                    onChange={(d) => handleDateChange(d, "alertInfo.endAt")}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </Grid>

              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />

        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={updateData}
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

DeviceConfig.propTypes = {
  className: PropTypes.string
};

export default DeviceConfig;
