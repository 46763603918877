import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Snackbars(props) {
  const classes = useStyles();
  const { openSnackbars, setOpenSnackbars } = props;
  const handleClick = () => {
    setOpenSnackbars(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbars({
      ...openSnackbars,
      open: false,
    });
  };

  return (
    <div className={classes.root}>
      <Snackbar open={openSnackbars.open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={openSnackbars.severity}>
          {openSnackbars.message}
        </Alert>
        {/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */}

      </Snackbar>
    </div>
  );
}
