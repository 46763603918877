import React, { useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import DeviceTable from './DeviceTable';
import Toolbar from './Toolbar';
import data from './data';
import { useAuthContext } from 'src/provider/AuthProvider';
import axios from 'axios';
import { getAllDevice } from "src/function/axios";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));



const DeviceListView = () => {
  const classes = useStyles();
  const [customers] = useState(data);
  const { authData } = useAuthContext();
  const [device, setDevice] = useState([]);



  React.useEffect(() => {
    console.log("DeviceListView useEffect:");

    async function fetchData() {
      let token = authData.token;

      getAllDevice({}, token)
        .then(res => {
          const result = res.data;
          console.log(result);
          if (result.result === "success") {
            setDevice(result.data)
          }
        })
        .catch(err => {
          console.log(err);
        })

      // axios.get("http://localhost:5001/device/getAllDevice", { params: { token: token } })
      //   .then(res => {
      //     const result = res.data;
      //     console.log(result);
      //     if (result.result === "success") {
      //       setDevice(result.data)
      //     }
      //   })
    }


    fetchData();
  }, []);


  return (
    <Page
      className={classes.root}
      title="Device"
    >
      <Container maxWidth={false}>
        <Typography variant={"h3"}>
          Device List
        </Typography>
        {/* <Toolbar /> */}
        <Box mt={3}>
          <DeviceTable devices={device} />
        </Box>
      </Container>
    </Page>
  );
};

export default DeviceListView;
