import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const Process = ({ className, data, ...rest }) => {
  const classes = useStyles();
  // const [orders] = useState(data);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Memory Usage" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={100}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  pid
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell sortDirection="desc">
                  <Tooltip
                    enterDelay={300}
                    title="Sort"
                  >
                    <TableSortLabel
                      active
                      direction="desc"
                    >
                      Usage
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                {/* <TableCell>
                  Status
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data ?
                data.map((proc) => (
                  <TableRow
                    hover
                    key={proc.pid}
                  >
                    <TableCell>
                      {proc.pid}
                    </TableCell>
                    <TableCell>
                      {proc.name}
                    </TableCell>
                    <TableCell>
                      {(proc.mem_vsz /1024/1024 ).toFixed(2) + "Mb" }
                    </TableCell>
                    {/* <TableCell>
                    <Chip
                      color="primary"
                      label={proc.status}
                      size="small"
                    />
                  </TableCell> */}
                  </TableRow>
                )) : null}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </Box>
    </Card>
  );
};

Process.propTypes = {
  className: PropTypes.string
};

export default Process;
