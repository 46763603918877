import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuthContext } from 'src/provider/AuthProvider';
import { useUIContext } from "src/provider/UIProvider";
import { getDeviceToken } from "src/function/axios";
import Skeleton from '@material-ui/lab/Skeleton';
import { localBaseUrl } from "src/function/axios";

import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  IconButton
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import TableLoadingRow from 'src/components/TableLoadingRow';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import SettingsIcon from '@material-ui/icons/Settings';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));



const DeviceTable = ({ className, devices, ...rest }) => {
  const navigate = useNavigate();
  const { authData } = useAuthContext();
  const {
    openSnackbars, setOpenSnackbars
  } = useUIContext();

  const classes = useStyles();
  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    let newSelectedDeviceIds;

    if (event.target.checked) {
      newSelectedDeviceIds = devices.map((devices) => devices.id);
    } else {
      newSelectedDeviceIds = [];
    }

    setSelectedDeviceIds(newSelectedDeviceIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedDeviceIds.indexOf(id);
    let newSelectedDeviceIds = [];

    if (selectedIndex === -1) {
      newSelectedDeviceIds = newSelectedDeviceIds.concat(selectedDeviceIds, id);
    } else if (selectedIndex === 0) {
      newSelectedDeviceIds = newSelectedDeviceIds.concat(selectedDeviceIds.slice(1));
    } else if (selectedIndex === selectedDeviceIds.length - 1) {
      newSelectedDeviceIds = newSelectedDeviceIds.concat(selectedDeviceIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedDeviceIds = newSelectedDeviceIds.concat(
        selectedDeviceIds.slice(0, selectedIndex),
        selectedDeviceIds.slice(selectedIndex + 1)
      );
    }

    setSelectedDeviceIds(newSelectedDeviceIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleDeviceView = (deviceId) => {
    navigate('/app/device/report?id=' + deviceId);
  };
  const handleDeviceConfigView = (deviceId) => {
    navigate('/app/device/config?id=' + deviceId);
  };

  const handleGetToken = (deviceId) => {
    let token = authData.token;

    getDeviceToken({ deviceId: deviceId }, token)
      .then(res => {
        const result = res.data;
        console.log(result);
        if (result.result === "success") {
          navigator.clipboard.writeText(result.data)
            .then(() => {
              setOpenSnackbars({
                open: true,
                severity: "success",
                message: "Token copied!"
              });
              // Success!
            })
            .catch(err => {
              console.log('Something went wrong', err);
            });
        }
      })

  };




  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={0}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedDeviceIds.length === devices.length}
                    color="primary"
                    indeterminate={
                      selectedDeviceIds.length > 0
                      && selectedDeviceIds.length < devices.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell> */}
                <TableCell>
                  Device Name
                </TableCell>
                <TableCell>
                  Description
                </TableCell>
                <TableCell align='center'>
                  Notifications
                </TableCell>
                <TableCell align='center'>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices.length != 0 ? devices.slice(0, limit).map((device, index) => (
                <TableRow
                  hover
                  key={index}
                  selected={selectedDeviceIds.indexOf(device.id) !== -1}
                >
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedDeviceIds.indexOf(device.id) !== -1}
                      onChange={(event) => handleSelectOne(event, device.id)}
                      value="true"
                    />
                  </TableCell> */}
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Box mr={2}>
                        <Avatar
                          alt={"a"}
                          src={localBaseUrl + device.deviceImage}
                          className={classes.large}
                        >
                          {device ? device.deviceName.charAt(0) : ""}
                        </Avatar>
                      </Box>

                      {device.isOnline ?
                        <FiberManualRecordIcon
                          style={{ color: '#32CD32', fontSize: 12 }} />
                        :
                        <FiberManualRecordIcon
                          style={{ color: '#c0c0c0', fontSize: 12 }} />}
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {device.deviceName}
                      </Typography>
                      {device.color ?
                        <FiberManualRecordIcon
                          style={{ color: device.color, fontSize: 12 }} />
                        : null}




                    </Box>
                  </TableCell>
                  <TableCell>
                    {device.description}
                  </TableCell>
                  <TableCell align='center'>
                    {device.isAlert ? <NotificationsActiveIcon /> : <NotificationsNoneIcon />}
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton onClick={() => handleDeviceView(device._id)}>
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeviceConfigView(device._id)}>
                      <SettingsIcon />
                    </IconButton>
                    {/* <IconButton onClick={() => handleGetToken(device.deviceId)}>
                      <VpnKeyIcon />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))
                :
                <>
                  <TableLoadingRow col={4} />
                  <TableLoadingRow col={4} />
                  <TableLoadingRow col={4} />
                </>}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={devices.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

DeviceTable.propTypes = {
  className: PropTypes.string,
  devices: PropTypes.array.isRequired
};

export default DeviceTable;
