import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { useAuthContext } from 'src/provider/AuthProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = (props) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { checkLogin } = useAuthContext();
  const [isloading, setIsloading] = useState(true);
  const navigate = useNavigate();


  React.useEffect(() => {
    console.log("Mainlayout useEffect:");

    async function fetchData() {
      setIsloading(true);
      // You can await here
      let checkLoginresult = await checkLogin();
      if (checkLoginresult) {
        console.log('checkLogin: ', checkLoginresult);
      } else {
        navigate('/login', { replace: true });
        console.log('checkLogin: ', checkLoginresult);
      }
      setIsloading(false);
    }
    fetchData();

  }, []);

  if (isloading) {
    return (
      <></>
    )
  } else {
    return (
      <div className={classes.root}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default DashboardLayout;
