import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIcon from '@material-ui/icons/Phone';
import TabletIcon from '@material-ui/icons/Tablet';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const Platform = ({ className, deviceData, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  // const data = {
  //   datasets: [
  //     {
  //       data: [63, 15, 22],
  //       backgroundColor: [
  //         colors.indigo[500],
  //         colors.red[600],
  //         colors.orange[600]
  //       ],
  //       borderWidth: 8,
  //       borderColor: colors.common.white,
  //       hoverBorderColor: colors.common.white
  //     }
  //   ],
  //   labels: ['Desktop', 'Tablet', 'Mobile']
  // };

  const [data, setData] = useState({});
  const [platform, setplatform] = useState([]);



  React.useEffect(() => {
    console.log("DeviceListView useEffect:");
    if (!deviceData) {
      return;
    }
    //get device playform
    let deviceList = deviceData.deviceList;
    let labels = {};
    let platformAry = [];

    for (const device of deviceList) {
      console.log('device: ', device.deviceInfo.os.distro);
      let devicePlatform = device.deviceInfo.os.distro;
      if (labels[devicePlatform]) {
        labels[devicePlatform]++;
      } else {
        labels[devicePlatform] = 1;
      }
    }
    console.log('labels: ', labels);

    let labelAry = [];
    let valueAry = [];

    for (const key of Object.keys(labels)) {
      labelAry.push(key);
      valueAry.push(labels[key])
      platformAry.push({
        title:key,
        value:(labels[key]/deviceList.length*100).toFixed(2),
      });
    }

    setplatform(platformAry);

    setData({
      datasets: [
        {
          data: valueAry,
          backgroundColor: [
            colors.indigo[500],
            colors.red[600],
            colors.orange[600]
          ],
          borderWidth: 8,
          borderColor: colors.common.white,
          hoverBorderColor: colors.common.white
        }
      ],
      labels: labelAry
    })
  }, [deviceData]);



  // const options = {
  //   animation: false,
  //   fill:false,
  //   cornerRadius: 20,
  //   layout: { padding: 0 },
  //   legend: { display: false },
  //   maintainAspectRatio: false,
  //   responsive: true,
  //   scales: {
  //     xAxes: [
  //       {
  //         barThickness: 12,
  //         maxBarThickness: 10,
  //         barPercentage: 0.5,
  //         categoryPercentage: 0.5,
  //         ticks: {
  //           fontColor: theme.palette.text.secondary
  //         },
  //         gridLines: {
  //           display: false,
  //           drawBorder: false
  //         }
  //       }
  //     ],
  //     yAxes: [
  //       {
  //         ticks: {
  //           fontColor: theme.palette.text.secondary,
  //           beginAtZero: true,
  //           min: 0
  //         },
  //         gridLines: {
  //           borderDash: [2],
  //           borderDashOffset: [2],
  //           color: theme.palette.divider,
  //           drawBorder: false,
  //           zeroLineBorderDash: [2],
  //           zeroLineBorderDashOffset: [2],
  //           zeroLineColor: theme.palette.divider
  //         }
  //       }
  //     ]
  //   },
  //   tooltips: {
  //     backgroundColor: theme.palette.background.default,
  //     bodyFontColor: theme.palette.text.secondary,
  //     borderColor: theme.palette.divider,
  //     borderWidth: 1,
  //     enabled: true,
  //     footerFontColor: theme.palette.text.secondary,
  //     intersect: false,
  //     mode: 'index',
  //     titleFontColor: theme.palette.text.primary
  //   }
  // };



  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };


  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Platform by Device" />
      <Divider />
      <CardContent>
        <Box
          height={300}
          position="relative"
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          {platform.map(({
            // color,
            // icon: Icon,
            title,
            value
          }) => (
            <Box
              key={title}
              p={1}
              textAlign="center"
            >
              {/* <Icon color="action" /> */}
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                // style={{ color }}
                variant="h2"
              >
                {value}
                %
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

Platform.propTypes = {
  className: PropTypes.string
};

export default Platform;
