import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import DeviceConfig from './DeviceConfig';
import moment from 'moment';


import { useAuthContext } from 'src/provider/AuthProvider';
import { useUIContext } from "src/provider/UIProvider";

import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';

import { getDeviceConfig, updateDeviceConfig, updateDeviceImage } from "src/function/axios";


let _ = require("lodash");

const queryString = require('query-string');

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DeviceConfigView = () => {
  const classes = useStyles();
  const location = useLocation();
  const { authData } = useAuthContext();
  const token = authData.token;

  const { openSnackbars, setOpenSnackbars } = useUIContext();


  const parsed = queryString.parse(location.search);
  let deviceId = parsed.id;
  const [data, setData] = useState(null);
  const [smartPlugs, setsmartPlugs] = useState(null)

  const [user, setuser] = useState({})
  const [newImage, setnewImage] = useState(null)

  async function fetchData() {

    getDeviceConfig({ deviceId: deviceId }, token)
      .then(res => {
        const result = res.data;
        console.log(result);
        if (result.result === "success") {
          let newData = result.data.deviceConfig;
          //set the time for alert
          if (newData.alertInfo.endAt) {
            let now = moment();
            let timeAry = _.split(newData.alertInfo.endAt, ':');
            now.set({ hour: timeAry[0], minute: timeAry[1], second: timeAry[2] })
            _.set(newData, 'alertInfo.endAt', now)
          }
          if (newData.alertInfo.startAt) {
            let now = moment();
            let timeAry = _.split(newData.alertInfo.startAt, ':');
            now.set({ hour: timeAry[0], minute: timeAry[1], second: timeAry[2] })
            _.set(newData, 'alertInfo.startAt', now)
          }
          console.log('newData: ', newData);
          setData(newData)

          setsmartPlugs(result.data.smartPlugList)

        }
      })
  }

  const updateData = () => {

    if (newImage) {
      var bodyFormData = new FormData();
      bodyFormData.append('deviceId', deviceId);
      bodyFormData.append('file', newImage);

      updateDeviceImage(bodyFormData)
        .then(res => {
          const result = res.data;
          console.log(result);
          if (result.result === "success") {

          } else {
            setOpenSnackbars({
              open: true,
              severity: "error",
              message: "Fail"
            });
          }
        })

    }

    console.log(data);

    let newData = _.cloneDeep(data);
    if (newData.alertInfo.endAt) {
      let time = moment(newData.alertInfo.endAt).format('HH:mm:ss');
      console.log('time: ', time);
      _.set(newData, 'alertInfo.endAt', time)
    }

    if (newData.alertInfo.startAt) {
      let time = moment(newData.alertInfo.startAt).format('HH:mm:ss');
      console.log('time: ', time);
      _.set(newData, 'alertInfo.startAt', time)
    }

    updateDeviceConfig({ data: newData }, token)
      .then(res => {
        const result = res.data;
        console.log(result);
        if (result.result === "success") {
          setOpenSnackbars({
            open: true,
            severity: "success",
            message: "Success!"
          });
        } else {
          setOpenSnackbars({
            open: true,
            severity: "error",
            message: "Fail"
          });
        }
      })
  }


  useEffect(() => {
    fetchData();
  }, [])

  return (
    <Page
      className={classes.root}
      title="Device Config"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          {/* <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <Profile user={user}/>
          </Grid> */}
          <Grid
            item
            xs={12}
          >
            <DeviceConfig data={data} setData={setData} updateData={updateData}
              newImage={newImage} setnewImage={setnewImage} smartPlugs={smartPlugs}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DeviceConfigView;
