import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import webSocket from 'socket.io-client'



import { getScreenCap } from "src/function/axios";
import { useAuthContext } from 'src/provider/AuthProvider';

import { baseUrl } from "src/function/axios";

import {
    TextField,
    makeStyles,
    Grid,
    Card,
    CardContent,
    Typography,
    CardActions,
    Switch,
    Box
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    screenCap: {
        width: "100%"
    },
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    },
    dot: {
        height: "10px",
        width: "10px",
        backgroundColor: "#bbb",
        borderRadius: "50%",
        display: "inline-block",
        marginRight: "2px"
    }
}));



export default function SshPopup(props) {
    const { open, setOpen, deviceId, data } = props;

    const classes = useStyles();
    const { authData } = useAuthContext();


    //state
    const [cmdLine, setCmdLine] = useState("")
    const [input, setInput] = useState("")
    const [ws, setWs] = useState(null)
    const [screenCap, setscreenCap] = useState(null)
    const [deviceOnline, setDeviceOnline] = useState(false)
    const [realtimeStat, setRealtimeStat] = useState(null)

    const [realtimeStatSwitch, setRaltimeStatSwitch] = useState(false)

    const [getRealtimeDatainterval, setGetRealtimeDatainterval] = useState(null)


    const initState = () => {
        setInput("");
        setCmdLine("");
        setscreenCap(null);
        setWs(null);
        setDeviceOnline(false);
        setRealtimeStat(null);
        setRaltimeStatSwitch(false)
    }



    let token = authData.token;


    const initWebSocket = () => {
        //對 getMessage 設定監聽，如果 server 有透過 getMessage 傳送訊息，將會在此被捕捉
        ws.on('getMessage', message => {
            console.log(message);
        })

        ws.on('device:connect', message => {
            console.log("device:connect" + message);
            setDeviceOnline(true);
        })

        ws.on('device:disconnect', message => {
            console.log("device:disconnect" + message);
            setDeviceOnline(false);
        })

        ws.on('result', message => {
            let result = message.result;
            let data = message.data;
            console.log(message);

            if (result == "shhResult") {
                console.log('data: ', data);
                setCmdLine(data);
            } else if (result == "screenshotResult") {
                setscreenCap(data);
            } else if (result == "realtimeStatResult") {
                console.log('data: ', data);
                setRealtimeStat(data)
            }
        })
    }


    const connectWebSocket = () => {
        //開啟
        setWs(webSocket(baseUrl))
    }


    React.useEffect(() => {

        if (open) {
            console.log("DeviceListView useEffect:");
            connectWebSocket();
        } else {
            if (ws) {
                ws.close();
                console.log("disconnect");
                initState();
            }
        }

    }, [open]);

    React.useEffect(() => {
        console.log("DeviceListView useEffect:");
        if (ws) {
            //連線成功在 console 中打印訊息
            console.log('success connect!')
            //設定監聽
            initWebSocket()

            //join DeviceId Room
            ws.emit('join', deviceId);
            console.log('join room: ' + deviceId)

            //set client data
            ws.emit("setClientData", { role: "webClient", deviceId: deviceId });

            ws.emit("connectDevice", deviceId);


        }
    }, [ws]);

    useEffect(() => {
        if (realtimeStatSwitch) {
            setGetRealtimeDatainterval(
                setInterval(() => {
                    console.log("runjob")
                    handleRealtimeStatButtonOnClick();
                }, 5000)
            )

            // getRealtimeDatainterval = 
        } else {
            console.log("set false")
            if (getRealtimeDatainterval != null) {
                clearInterval(getRealtimeDatainterval);
                setGetRealtimeDatainterval(null)
            }
        }
    }, [realtimeStatSwitch])


    const handleSendCommand = (input) => {
        // ws.emit('shhSend', input);
        ws.emit('action', { action: "shhSend", data: input });
    };

    const handleGetScreenCap = () => {
        ws.emit('action', { action: "screenshot" });

    };

    const handleConnectButtonOnClick = () => {
        if (deviceOnline) {
            ws.close();
            setWs(null);
            setDeviceOnline(false);
        } else {
            if (!ws) {
                connectWebSocket();
            }
        }
    };

    const handleRealtimeStatButtonOnClick = () => {
        ws.emit('action', { action: "realtimeStat" });
    };

    const handleReatimeSwitch = () => {
        setRaltimeStatSwitch(oldValue => {
            return !oldValue
        })
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={"xl"}
            fullWidth
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogTitle id="alert-dialog-title">{"Live control panel"}</DialogTitle>

            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={4}>

                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Device Status
                                </Typography>
                                {deviceOnline ?
                                    <>
                                        <span className={classes.dot} style={{ backgroundColor: "#00FF00" }}></span>
                                        {"online"}
                                    </>
                                    :
                                    <>
                                        <span className={classes.dot} style={{ backgroundColor: "#FF0000" }}></span>
                                        {"offline"}
                                    </>
                                }
                            </CardContent>
                            <CardActions>
                                <Button onClick={handleConnectButtonOnClick} size="small">{
                                    deviceOnline ?
                                        "disconnect"
                                        :
                                        "reconnect"}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    <Grid item xs={8}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Real Time Stat
                                </Typography>
                                {realtimeStat ?
                                    <>
                                        {
                                            "cpu" + parseInt(realtimeStat.cpu).toFixed(1) + "%" +
                                            "ram" + (realtimeStat.ram * 100).toFixed(1) + "%" +
                                            "ping" + parseInt(realtimeStat.ping).toFixed(1)
                                        }

                                    </> :
                                    ` `
                                }
                            </CardContent>
                            <CardActions>
                                <Button onClick={handleRealtimeStatButtonOnClick} size="small">
                                    Get Data
                                </Button>
                                <Switch
                                    checked={realtimeStatSwitch}
                                    onChange={handleReatimeSwitch}
                                    name="realtimeStatSwitch"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            </CardActions>
                        </Card>
                    </Grid>


                    <Grid item xs={12}>
                        <TextField
                            id="standard-multiline-flexible"
                            label="Multiline"
                            disabled={!deviceOnline}
                            fullWidth
                            multiline
                            rowsMax={30}
                            value={cmdLine}
                        />
                        <TextField
                            id="standard-password-input"
                            label="cmd"
                            fullWidth
                            disabled={!deviceOnline}
                            value={input}
                            type="text"
                            name={"input"}
                            onChange={(event) => { setInput(event.target.value) }}
                            onKeyPress={(ev) => {
                                console.log(`Pressed keyCode ${ev.key}`);
                                if (ev.key === 'Enter') {
                                    // Do code here
                                    let oldInput = input;
                                    console.log(input);
                                    // setCmdLine(cmdLine + oldInput + '\n');
                                    handleSendCommand(oldInput);
                                    setInput("");
                                    ev.preventDefault();
                                }
                            }}
                        />

                    </Grid>

                    <Grid item xs={12}>
                        <Box display='flex'>
                            {
                                data && data.deviceInfo.command ?
                                    data.deviceInfo.command.map((command, index) => (
                                        <Box key={index} mr={1}>
                                            <Button variant="outlined" color="primary"
                                                style={{ textTransform: 'none', }}
                                                disabled={!deviceOnline}
                                                onClick={()=>handleSendCommand(command)}
                                            >
                                                {command}
                                            </Button>
                                        </Box>

                                    ))
                                    :
                                    null
                            }
                        </Box>

                    </Grid>


                    <Grid item xs={3}>
                        <Button variant="outlined" color="primary" disabled={!deviceOnline} onClick={() => { handleGetScreenCap() }}>
                            ScreenCap
                        </Button>
                    </Grid>
                    <Grid item xs={9}>
                        {
                            screenCap ?
                                <img
                                    className={classes.screenCap}
                                    src={"data:image/png;base64, " + screenCap} alt="Red dot" /> :
                                null
                        }
                    </Grid>
                </Grid>

                <br />

            </DialogContent>

        </Dialog>

    );
}
