import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useAuthContext } from 'src/provider/AuthProvider';
import { useUIContext } from "src/provider/UIProvider";
import { localBaseUrl } from "src/function/axios";
import { setChannelStatus,sendMsg } from "src/function/axios";

//date picker 
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  Switch,
  InputAdornment,
  IconButton,
  Avatar,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import WifiIcon from '@material-ui/icons/Wifi';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

let _ = require("lodash");

const useStyles = makeStyles((theme) => ({
  root: {},
  colorPicker: {
    fontSize: 40
  },
  colorPickerBorder: {
    border: "1px solid red",
    borderRadius: "50%",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const SmartPlugConfig = ({ className, data, setData, updateData, newImage, setnewImage, ...rest }) => {
  const classes = useStyles();
  const { authData } = useAuthContext();
  const token = authData.token;

  const [serialNumberLock, setserialNumberLock] = useState(true);

  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
  const [checked, setChecked] = React.useState(['wifi']);

  const [command, setcommand] = useState('')

  const {
    colorOption
  } = useUIContext();

  const handleChange = (event) => {
    let { name, value } = event.target;
    setData(oriData => {
      return _.set(_.clone(oriData), name, value)
    });
  };

  const handleSendMsg = () => {
    console.log('command: ');

    if (_.trim(command) == '') {
      return
    }

    sendMsg({ mac: data.mac, command: command}, token).then(res => {
      const result = res.data;
      console.log(result);
      if (result.result === "success") {
       
      }
    })

  };

  const handleSwitchChange = (event) => {
    let { name } = event.target;
    let currentValue = data[name] ? 1 : 0;
    let status = currentValue == 1 ? 0 : 1;

    console.log('name, value: ', name, currentValue);
    let channel = name.substr(name.length - 1);
    console.log('channel: ', channel);

    setChannelStatus({ mac: data.mac, channel: channel, status: status }, token).then(res => {
      const result = res.data;
      console.log(result);
      if (result.result === "success") {
        setData(oriData => {
          return _.set(_.clone(oriData), name, !oriData[name])
        });
      }
    })

  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          subheader=" "
          title="Smart Plug Config"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3} alignContent="center">
            <Grid item xs={12} >
              <Box display="flex" flexDirection="row" alignContent="center">
                <Box alignSelf="center">
                  Channel 1
                </Box>
                <Box alignSelf="center">
                  <Switch
                    checked={data && data.channel1 || false}
                    onChange={handleSwitchChange}
                    name="channel1"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </Box>

                <Box alignSelf="center">
                  Channel 2
                </Box>
                <Box alignSelf="center">
                  <Switch
                    checked={data && data.channel2 || false}
                    onChange={handleSwitchChange}
                    name="channel2"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </Box>


                <Box alignSelf="center">
                  Channel 3
                </Box>
                <Box alignSelf="center">
                  <Switch
                    checked={data && data.channel3 || false}
                    onChange={handleSwitchChange}
                    name="channel3"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </Box>

                <Box alignSelf="center">
                  Channel 4
                </Box>
                <Box alignSelf="center">
                  <Switch
                    checked={data && data.channel4 || false}
                    onChange={handleSwitchChange}
                    name="channel4"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </Box>
              </Box>

              <TextField
                fullWidth
                label="Command"
                name="command"
                margin='dense'
                required
                onChange={(event) => { console.log(event.target.value); setcommand(event.target.value) }}
                value={command}
                variant="outlined"
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    // Do code here
                    handleSendMsg();
                    ev.preventDefault();
                  }
                }}
              />

            </Grid>
          </Grid>
        </CardContent>

        {/* ---------------------------------------------------------Alert--------------------------------------------------------- */}





        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={updateData}
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

SmartPlugConfig.propTypes = {
  className: PropTypes.string
};

export default SmartPlugConfig;
