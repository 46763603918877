import React, { createContext, useState, useEffect, useMemo, useContext } from "react";
import axios from 'axios';

export const UIContext = createContext();

class UIProvider extends React.Component {
    state = {
        openSnackbars: {
            open: false,
            severity: "success",
            message: "Delete Succeeded"
        },
    };

    colorOption = [
        {
            value: '1446A0',
            label: 'Cobalt Blue'
        },
        {
            value: 'DB3069',
            label: 'Razzmatazz'
        },
        {
            value: 'F5D547',
            label: 'Mustard'
        },
        {
            value: '96897B',
            label: 'Middle Grey'
        },
        {
            value: '648767',
            label: 'Russian Green'
        },
        {
            value: '41EAD4',
            label: 'Turuiose'
        },
        {
            value: '909CC2',
            label: 'Wild Blue Yonder'
        },
        {
            value: 'B8B8FF',
            label: 'Maximun Blue Purle'
        },
    ]

    componentDidMount() {

    }

    render() {
        return (
            <UIContext.Provider value={{
                openSnackbars: this.state.openSnackbars,
                colorOption: this.colorOption,
                setOpenSnackbars: (value) => {
                    this.setState({
                        ...this.state,
                        openSnackbars: value,
                    });
                },


            }} >
                {this.props.children}
            </UIContext.Provider>
        )
    }
}

export const useUIContext = () => useContext(UIContext);
export default UIProvider;