import React, { useState } from 'react';
import axios from 'axios';
import webSocket from 'socket.io-client'
import { getDashboardData } from "src/function/axios";
import hexToRgba from 'hex-to-rgba';


import {
  Container,
  Grid,
  makeStyles,
  Button,
  Typography,
  Box
} from '@material-ui/core';
import Page from 'src/components/Page';
import Budget from './Budget';
import LatestOrders from './LatestOrders';
import LatestProducts from './LatestProducts';
import Network from './Network';
import Cpu from './Cpu';
import Ram from './Ram';
import Process from './Process';
import SshPopup from './SshPopup';

import LineChart from './LineChart';
import Platform from './Platform';



import TasksProgress from './TasksProgress';
import TotalCustomers from './TotalCustomers';
import TotalProfit from './TotalProfit';
import TrafficByDevice from './TrafficByDevice';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { useAuthContext } from 'src/provider/AuthProvider';


import InfoCard from './InfoCard';

const queryString = require('query-string');

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const { authData } = useAuthContext();
  const [sshOpen, setSshOpen] = useState(false)

  const parsed = queryString.parse(location.search);
  let deviceId = parsed.id;

  const [data, setData] = useState(null);

  //ws



  async function fetchData() {
    let token = authData.token;

    getDashboardData({}, token)
      .then(res => {
        const result = res.data;
        console.log(result);
        if (result.result === "success") {

          let deviceList = result.data.deviceList;
          deviceList = deviceList.filter(item => item.isOnline);
          console.log('deviceList: ', deviceList);
          if (deviceList.length == 0) {
            setData(result.data)
            return
          }
          //labels
          let labels = deviceList[0].data.map(item => item.timestamp)
          labels = labels.map(item => (item.substring(8, 10) + ":" + item.substring(10, 12)))
          console.log('labels: ', labels);
          result.data["labels"] = labels;

          //color 
          for (const device of deviceList) {
            if (device.color) {
              device.color = hexToRgba(device.color);
            }
          }


          setData(result.data)
        }
      })
  }


  React.useEffect(() => {
    console.log("DeviceListView useEffect:");

    //web socket
    // connectWebSocket();
    console.log('deviceId: ', deviceId);

    fetchData();
  }, []);


  return (
    <Page
      className={classes.root}
      title="DeviceReport"
    >


      {/* <SshPopup open={sshOpen} setOpen={setSshOpen} deviceId={deviceId} />

      <Button variant="outlined" color="primary" onClick={() => { setSshOpen(true) }}>
        Open alert dialog
      </Button> */}


      <Container maxWidth={false}>
        <Box mb={3}>
          <Typography variant={"h3"}>
            Dashboard
        </Typography>
        </Box>

        <Grid
          container
          spacing={3}
        >
          <Grid item lg={3} sm={6} xl={3} xs={12} >
            <InfoCard title={"Device Count"} value={data ? data.deviceCount : ""} />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} >
            <InfoCard title={"last Update Time"} value={data ? data.lastUpdateTime : ""} />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12} >
            <InfoCard title={"Online device"} value={data ? data.onlineDeviceCount : ""} />
          </Grid>

          <Grid
            item
            // lg={6}
            // md={12}
            // xl={6}
            xs={12}
          >
            <LineChart deviceData={data} dataName={"ping"} title={"Network Ping (ms)"} />
          </Grid>

          <Grid
            item
            // lg={6}
            // md={12}
            // xl={6}
            xs={12}
          >
            <LineChart deviceData={data} dataName={"cpu"} title={"Cpu Usage (%)"} />
          </Grid>


          <Grid
            item
            // lg={6}
            // md={12}
            // xl={6}
            xs={12}
          >
            <LineChart deviceData={data} dataName={"ram"} title={"Memory Usage (%)"} />
          </Grid>

          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <Platform deviceData={data} />
          </Grid>

          {/* <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <LatestProducts />
          </Grid> */}

          {/* 
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <LatestOrders />
          </Grid> */}

        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
