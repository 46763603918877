import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    TableCell,
    TableRow,
} from '@material-ui/core';
export default function TableLoadingRow(props) {
    const { col } = props;
    const items = [];

    for (let index = 0; index < col; index++) {
        items.push(
            <TableCell key={index}>
                <Skeleton />
            </TableCell>
        )
    }

    return (
        <TableRow>
            {items}
        </TableRow>
    );
}
