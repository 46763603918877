import axios from 'axios';
import { Form } from 'formik';

export const localBaseUrl = "http://139.162.126.206:5001"
// export const localBaseUrl = "http://localhost:5001"
// export const localBaseUrl = "http://192.168.0.122:5001"

export const smartPlugBaseUrl = "http://localhost:6001"


//io url
export const baseUrl = "http://139.162.126.206:5001";

const qureyString = (data) => {
    let qureyString = "";
    for (const item of Object.keys(data)) {
        qureyString += item + "=" + data[item] + "&";
    }
    return qureyString.slice(0, -1)
}

const cmsRequest = axios.create({
    baseURL: localBaseUrl + '/cms/'
});

const deivceRequest = axios.create({
    baseURL: localBaseUrl + '/device/'
});
const usersRequest = axios.create({
    baseURL: localBaseUrl + '/users/'
});
const dashboardRequest = axios.create({
    baseURL: localBaseUrl + '/dashboard/'
});

const smartPlugRequest = axios.create({
    baseURL: smartPlugBaseUrl + '/pyapi/'
});

const deivceForm = axios.create({
    baseURL: localBaseUrl + '/device/',
    headers: { 'Content-Type': 'multipart/form-data' }
});

//import { setChannelStatus } from "src/function/axios";

// getAllDevice({token: token}).then(res => {
//       const result = res.data;
//       console.log(result);
//       if (result.result === "success") {
//       }
//     })
//     .catch(err => {
//       console.log(err);
//     })

//cms
// authtoken
export const authtoken = data => cmsRequest.post(`/authtoken`, data);
export const login = data => cmsRequest.post(`/login`, data);

//users
export const getUserToken = data => usersRequest.get(`/getUserToken?${qureyString(data)}`);
export const getUserProfile = data => usersRequest.get(`/getUserProfile?${qureyString(data)}`);

//device
export const getAllDevice = (data, token) => deivceRequest.get(`/getAllDevice${qureyString(data)}`, { headers: { Authorization: token, } });
export const getDeviceToken = (data, token) => deivceRequest.get(`/getDeviceToken?${qureyString(data)}`, { headers: { Authorization: token, } });
export const getDeviceData = (data, token) => deivceRequest.get(`/getDeviceData?${qureyString(data)}`, { headers: { Authorization: token, } });
export const getScreenCap = (data, token) => deivceRequest.get(`/getScreenCap?${qureyString(data)}`, { headers: { Authorization: token, } });
export const getDeviceConfig = (data, token) => deivceRequest.get(`/getDeviceConfig?${qureyString(data)}`, { headers: { Authorization: token, } });

////device config
export const updateDeviceConfig = (data, token) => deivceRequest.post(`/updateDeviceConfig`, data, { headers: { Authorization: token, } });
export const updateDeviceImage = (data, token) => deivceRequest.post(`/updateDeviceImage`, data, { headers: { Authorization: token, } });
export const getDeviceHistoryData = (data, token) => deivceRequest.get(`/getDeviceHistoryData?${qureyString(data)}`, { headers: { Authorization: token, } });

//Dashboard
export const getDashboardData = (data, token) => dashboardRequest.get(`/getDashboardData${qureyString(data)}`, { headers: { Authorization: token, } });

//smartPlugRequest
export const getDeviceOnlone = (data, token) => smartPlugRequest.get(`/getDeviceOnlone?${qureyString(data)}`, { headers: { Authorization: token, } });
export const getChannelStatus = (data, token) => smartPlugRequest.get(`/getChannelStatus?${qureyString(data)}`, { headers: { Authorization: token, } });
export const setChannelStatus = (data, token) => smartPlugRequest.get(`/setChannelStatus?${qureyString(data)}`, { headers: { Authorization: token, } });
export const sendMsg = (data, token) => smartPlugRequest.get(`/sendMsg?${qureyString(data)}`, { headers: { Authorization: token, } });

// 搜尋相關的 api
// export const apiSearch = data => searchRequest.get(`/Search?searchdata=${data}`);
// export const apiSearchType = () => searchRequest.get(`/SearchType`);
// export const apiUserLogin = data => userRequest.post('/signIn', data);