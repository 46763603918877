import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar, Line } from 'react-chartjs-2';
import * as ChartAnnotation from 'chartjs-plugin-annotation';

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    useTheme,
    makeStyles,
    colors
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useAuthContext } from 'src/provider/AuthProvider';

const useStyles = makeStyles(() => ({
    root: {}
}));

const LinChart = ({ deviceData, className, dataName, title, ...rest }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { authData } = useAuthContext();

    const [data, setData] = useState({});
    const [avg, setAvg] = useState(0)

    React.useEffect(() => {
        console.log('deviceData: ', deviceData);
        if (!deviceData || deviceData.onlineDeviceCount == 0 || deviceData.labels.length == 0) {

            return;
        }
        let deviceList = deviceData.deviceList;
        deviceList = deviceList.filter(item => item.isOnline);
        let allDataset = [];
        for (const device of deviceList) {
            let lineData = device.data.map(item => item.data ? item.data[dataName] : 0);
            lineData = lineData.map(item => item.toFixed(2));
            allDataset.push({
                fill: false,
                borderColor: device.color ? device.color : 'rgba(220,20,60,1)',
                data: lineData,
                label: device.deviceName,
                borderWidth: 1,
                pointRadius: 1,
            })
        }
        let avgLine = [];

        for (let i = 0; i < deviceData.labels.length; i++) {
            let total = 0;
            let totalDeviceCount = 0;
            for (const device of deviceList) {
                if (device.data[i] && device.data[i].data) {
                    total += device.data[i].data[dataName];
                    totalDeviceCount++;
                }
            }
            if (totalDeviceCount > 0) {
                avgLine.push((total / totalDeviceCount).toFixed(2))
            } else {
                avgLine.push(0)
            }
        }
        // console.log('avgLine: ', avgLine);

        allDataset.push({
            fill: true,
            borderColor: 'rgba(black,1)',
            data: avgLine,
            label: "avarge",
            borderDash: [5, 2],
            pointRadius: 0,
            borderWidth: 2,
        })

        setData({
            datasets: allDataset,
            labels: deviceData.labels,

        });

    }, [deviceData]);

    var options = {
        showDatasetLabels: true,
        animation: false,
        fill: false,
        cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: false },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            xAxes: [
                {
                    barThickness: 12,
                    maxBarThickness: 10,
                    barPercentage: 0.5,
                    categoryPercentage: 0.5,
                    ticks: {
                        fontColor: theme.palette.text.secondary
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false
                    }
                }
            ],
            yAxes: [
                {
                    // scaleLabel: {
                    //     display: true,
                    //     labelString: 'ms'
                    // },
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                        beginAtZero: true,
                        min: 0
                    },
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: theme.palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: theme.palette.divider
                    }
                }
            ]
        },
        tooltips: {
            backgroundColor: theme.palette.background.default,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'nearest',
            titleFontColor: theme.palette.text.primary
        },
        legend: {
            display: true,
            position: "top",
            labels: {
                fontColor: "#323130",
                fontSize: 14
            }
        },
        // annotation: {
        //     annotations: [{
        //         type: 'line',
        //         mode: 'horizontal',
        //         scaleID: 'y-axis-0',
        //         value: avg,
        //         borderDash: [2, 2],

        //         borderColor: 'rgb(75, 192, 192)',
        //         borderWidth: 2,
        //         label: {
        //             enabled: false,
        //             content: 'Test label'
        //         }
        //     }]
        // }
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardHeader
                // action={(
                //   <Button
                //     endIcon={<ArrowDropDownIcon />}
                //     size="small"
                //     variant="text"
                //   >
                //     Last 7 days
                //   </Button>
                // )}
                title={title}
            />
            <Divider />
            <CardContent>
                <Box
                    height={400}
                    position="relative"
                >
                    {/* <Bar
            data={data}
            options={options}
          /> */}
                    <Line
                        data={data}
                        options={options}
                    />
                </Box>
            </CardContent>
            <Divider />
            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
                <Button
                    color="primary"
                    endIcon={<ArrowRightIcon />}
                    size="small"
                    variant="text"
                >
                    Overview
        </Button>
            </Box>
        </Card>
    );
};

LinChart.propTypes = {
    className: PropTypes.string
};

export default LinChart;
