import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import { getUserProfile } from "src/function/axios";
import { useAuthContext } from 'src/provider/AuthProvider';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = () => {
  const classes = useStyles();
  const { authData } = useAuthContext();

  const [user, setUser] = useState(null)

 
  useEffect(() => {
    let token = authData.token;

    getUserProfile({ token: token }).then(res => {
      const result = res.data;
      console.log(result);
      if (result.result === "success") {
        setUser(result.data);
      }
    })
      .catch(err => {
        console.log(err);
      })

  }, [])

  return (
    <Page
      className={classes.root}
      title="Account"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <Profile user={user} />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <ProfileDetails user={user} setUser={setUser} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
