import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

import SmartPlugConfig from './SmartPlugConfig';
import moment from 'moment';


import { useAuthContext } from 'src/provider/AuthProvider';
import { useUIContext } from "src/provider/UIProvider";

import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';

import { getDeviceConfig, updateDeviceConfig, updateDeviceImage } from "src/function/axios";
import { getDeviceOnlone, getChannelStatus } from "src/function/axios";

let _ = require("lodash");
const queryString = require('query-string');
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SmartPlugConfigView = () => {
  const classes = useStyles();
  const location = useLocation();
  const { authData } = useAuthContext();
  const token = authData.token;

  const { openSnackbars, setOpenSnackbars } = useUIContext();


  const parsed = queryString.parse(location.search);
  let smartPlugMac = parsed.mac;
  const [data, setData] = useState(null);

  const [user, setuser] = useState({})
  const [newImage, setnewImage] = useState(null)

  async function fetchData() {
    let apiResult = {};
    let channelCount = 4;
    let promiseAry = []
    apiResult['channelCount'] = 4;
    apiResult['mac'] = smartPlugMac;


    const promise1 = getDeviceOnlone({ mac: smartPlugMac }, token).then(res => {
      const result = res.data;
      console.log(result);
      if (result.result === "success") {
        let newData = result.data;
        apiResult['online'] = result.data;
      }
    })
    promiseAry.push(promise1);

    for (let index = 1; index <= channelCount; index++) {
      promiseAry.push(
        getChannelStatus({  mac: smartPlugMac, channel: index },token).then(res => {
          const result = res.data;
          console.log(result);
          if (result.result === "success") {
            let newData = result.data;
            apiResult['channel' + index] = parseInt(result.data) == 1;
          }
        })
      )
    }

    Promise.all(promiseAry).then(function (values) {
      setData(apiResult);
      console.log('apiResult: ', apiResult);
    });

  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <Page
      className={classes.root}
      title="Device Config"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          {/* <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <Profile user={user}/>
          </Grid> */}
          <Grid
            item
            xs={12}
          >
            <SmartPlugConfig data={data} setData={setData}
              newImage={newImage} setnewImage={setnewImage}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default SmartPlugConfigView;
