import React, { createContext, useState, useEffect, useMemo, useContext } from "react";
import axios from 'axios';
import { authtoken } from "src/function/axios";

export const AuthContext = createContext();

class AuthProvider extends React.Component {
    state = { authData: null };

    componentDidMount() {
        const currentAuthData = localStorage.getItem('login');
        if (currentAuthData) {
            const jsonData = JSON.parse(currentAuthData);
            // this.setState = {MyContext};

            this.setState({
                authData: jsonData
            });

            // console.log(this.state);
        }
    }

    render() {
        return (
            <AuthContext.Provider value={{
                authData: this.state.authData,
                onlogin: (data) => {
                    console.log(data);
                    localStorage.setItem('login', JSON.stringify(data));
                    this.setState({
                        ...this.state,
                        authData: data,
                    }
                    )
                },
                onlogout: () => {
                    localStorage.removeItem('login');
                    this.setState({ authData: null })
                },
                checkLogin: async () => {
                    let token
                    if (this.state.authData) {
                        token = this.state.authData.token;
                    } else {
                        const currentAuthData = localStorage.getItem('login');
                        if (currentAuthData) {
                            const jsonData = JSON.parse(currentAuthData);
                            token = jsonData.token
                        } else {
                            return false
                        }
                    }

                    // authtoken({ token: token }).then(res => {
                    //     const result = res.data;
                    //     console.log(result);
                    //     if (result.result === 'success') {
                    //         return true
                    //     } else {
                    //         return false
                    //     }
                    // })

                    let res = await authtoken({ token: token });


                    const result = res.data;
                    console.log(result);
                    if (result.result === 'success') {
                        return true
                    } else {
                        return false
                    }
                }
            }} >
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}


export const useAuthContext = () => useContext(AuthContext);


export default AuthProvider;