import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import AuthProvider from "src/provider/AuthProvider";
import UIProvider from "src/provider/UIProvider";



ReactDOM.render((
  <BrowserRouter>
    <AuthProvider>
      <UIProvider>
        <App />
      </UIProvider>
    </AuthProvider>
  </BrowserRouter>
), document.getElementById('root'));

serviceWorker.register();
